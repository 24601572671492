import { Component } from '@angular/core'
import { Breakpoint } from '@models/breakpoints.types'
import { fadeAnimation } from 'app/shared/animations/fade.animation'

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [fadeAnimation],
})
export class LayoutComponent {
  readonly Breakpoint = Breakpoint
}
