import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'scheduleRangeTime',
})
export class ScheduleRangeTimePipe implements PipeTransform {
  transform(value: string): string {
    return value.split(':').slice(0, 2).join(':')
  }
}
