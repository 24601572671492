import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { NgModel } from '@angular/forms'
import { DEFAULT_DEBOUNCE_TIME } from '@helpers/general.helper'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, skip, takeUntil } from 'rxjs/operators'

@Directive({
  selector: '[appModelChangeDebounce]',
})
export class ModelChangeDebouncedDirective implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>()

  @Input()
  appModelChangeDebounceTime = DEFAULT_DEBOUNCE_TIME

  @Output()
  readonly appModelChangeDebounce = new EventEmitter<any>()

  constructor(private readonly ngModel: NgModel) {}

  ngOnInit(): void {
    if (!this.ngModel.valueChanges) {
      return
    }

    this.ngModel.valueChanges
      .pipe(skip(1), distinctUntilChanged(), debounceTime(this.appModelChangeDebounceTime), takeUntil(this.destroy$))
      .subscribe(value => this.appModelChangeDebounce.emit(value))
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }
}
