export enum BroadcastChannelMessageType {
  USER_ACTIVITY = 'USER_ACTIVITY',
  USER_INACTIVITY = 'USER_INACTIVITY',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  CLEAR_AUTH_SESSION = 'CLEAR_AUTH_SESSION',
  LOGOUT_DELAY_LEFT = 'LOGOUT_DELAY_LEFT',
  UPDATE_APP = 'UPDATE_APP',
}

export interface BroadcastChannelMessage {
  type: BroadcastChannelMessageType
  data?: BroadcastChannelMessageData
}

export interface BroadcastChannelMessageData {
  idleError?: boolean
  secondsLeft?: number
}
