import { Component, Input } from '@angular/core'
import { UserInfoDto } from '@models/api/userInfoDto'

import { AuthService } from '@services/auth/auth.service'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-auth-top-bar',
  templateUrl: './auth-top-bar.component.html',
  styleUrls: ['./auth-top-bar.component.scss'],
})
export class AuthTopBarComponent {
  readonly loggedUser$: Observable<UserInfoDto | undefined> = this.auth.userChanges$

  @Input()
  showLogout = false

  constructor(private readonly auth: AuthService) {}
}
