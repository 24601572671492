import { Component, Input } from '@angular/core'
import { InputControlAbstractComponent } from '@components/input-control/input-control.abstract'

@Component({
  selector: 'app-input-control [form] [control]',
  templateUrl: './input-control.component.html',
  styleUrls: ['./input-control.component.scss'],
})
export class InputControlComponent extends InputControlAbstractComponent {
  @Input()
  maxLength = 255
}
