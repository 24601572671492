import { Directive, ViewContainerRef, TemplateRef, Input, OnInit, OnDestroy } from '@angular/core'
import { Breakpoint, BreakpointDirectiveMode } from '@models/breakpoints.types'
import { BreakpointsService } from '@services/breakpoints/breakpoints.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Directive({
  selector: '[appBreakpoint]',
})
export class BreakpointDirective implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>()

  @Input()
  appBreakpoint!: Breakpoint | Breakpoint[]
  @Input()
  appBreakpointMode: BreakpointDirectiveMode = 'include'

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly breakpoints: BreakpointsService,
  ) {}

  ngOnInit(): void {
    this.breakpoints.currentBreakpoint$
      .pipe(takeUntil(this.destroy$))
      .subscribe(breakpoint => this.handleBreakpointChange(breakpoint))
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.viewContainer.clear()
  }

  private handleBreakpointChange(breakpoint: Breakpoint): void {
    const included = this.breakpointIncluded(breakpoint)

    if (this.appBreakpointMode === 'include') {
      this.createOrClearView(included)
    } else {
      this.createOrClearView(!included)
    }
  }

  private createOrClearView(included: boolean): void {
    const rendered = !!this.viewContainer.get(0)

    if (included) {
      if (rendered) {
        return
      }

      this.viewContainer.createEmbeddedView(this.templateRef)
    } else if (rendered) {
      this.viewContainer.clear()
    }
  }

  private breakpointIncluded(breakpoint: Breakpoint): boolean {
    return this.appBreakpoint === breakpoint || this.appBreakpoint.includes(breakpoint)
  }
}
