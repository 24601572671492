import { Component, Input } from '@angular/core'
import { ApiValidationErrorMsgDto } from '@models/api-errors.types'

@Component({
  selector: 'app-server-errors',
  template: `
    <div *ngIf="serverError" class="form-field-error">
      {{ 'serverErrors.' + serverError | translate }}
    </div>
  `,
})
export class ServerErrorsComponent {
  serverError?: string

  @Input()
  fieldName!: string

  @Input()
  set errorMessages(errors: ApiValidationErrorMsgDto[]) {
    if (!errors) {
      return
    }

    const error = errors.find(({ field }) => field === this.fieldName)

    this.serverError = error?.msgKey
  }
}
