import { Injectable } from '@angular/core'
import { UserProfileResponseDto } from '@models/api/userProfileResponseDto'
import { HttpClientService } from '@services/http/http-client.service'
import { Observable } from 'rxjs'

@Injectable()
export class TopBarApiService {
  constructor(private readonly http: HttpClientService) {}

  getUserProfile(): Observable<UserProfileResponseDto> {
    return this.http.get('/profile')
  }
}
