import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { Injectable, OnDestroy } from '@angular/core'
import { Breakpoint } from '@models/breakpoints.types'
import { Subject } from 'rxjs'
import { map, shareReplay, takeUntil } from 'rxjs/operators'
import { CdkMatchingBreakpoints, MatchingBreakpoint } from '../../models/breakpoints.types'

@Injectable({
  providedIn: 'root',
})
export class BreakpointsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>()
  currentBreakpoint$ = this.breakpointObserver.observe(Object.values(Breakpoint)).pipe(
    map(({ breakpoints }: BreakpointState) => this.mapToCurrentBreakpoint(breakpoints)),
    takeUntil(this.destroy$),
    shareReplay(1),
  )

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  private mapToCurrentBreakpoint(breakpoints: CdkMatchingBreakpoints): Breakpoint {
    const [{ breakpoint }]: MatchingBreakpoint[] = Object.entries(breakpoints)
      .filter(([_, matches]) => matches)
      .map(([breakpoint, matches]) => ({
        breakpoint: breakpoint as Breakpoint,
        matches,
      }))

    return breakpoint
  }
}
