import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'

@Component({
  selector: 'app-page-with-header',
  templateUrl: './page-with-header.component.html',
  styleUrls: ['./page-with-header.component.scss'],
})
export class PageWithHeaderComponent implements AfterViewInit {
  @ViewChild('pageContainer')
  private readonly pageContainer?: ElementRef

  @Input()
  fullWidth = false

  @Output()
  readonly containerLoad = new EventEmitter<HTMLElement>()

  ngAfterViewInit(): void {
    this.containerLoad.emit(this.pageContainer!.nativeElement)
  }
}
