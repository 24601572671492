import { Injectable } from '@angular/core'
import { HttpClientService } from '@services/http/http-client.service'
import { Observable } from 'rxjs'
import { ListDtoNationalityDictResponseDto } from '@models/api/listDtoNationalityDictResponseDto'

@Injectable({
  providedIn: 'root',
})
export class NationalitiesApiService {
  constructor(private readonly http: HttpClientService) {}

  getNationalities(): Observable<ListDtoNationalityDictResponseDto> {
    return this.http.get<ListDtoNationalityDictResponseDto>('/nationalities')
  }
}
