<app-progress-button
  classes="p-button-rounded p-button-text"
  type="button"
  iconPath="assets/icons/logout.svg"
  iconPlacement="right"
  [loading]="pending"
  [disabled]="pending"
  (clicked)="openLogoutConfirmDialog()"
>
  {{ 'general.logout' | translate }}
</app-progress-button>
