import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'truncateTextLongerThan',
})
export class TruncateTextLongerThanPipe implements PipeTransform {
  transform(text?: string, maxLength = text?.length): string | undefined {
    if (text && text?.length > maxLength!) {
      return `${text.slice(0, maxLength)}...`
    }

    return text
  }
}
