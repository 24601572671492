import { Component, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import {
  CONFIRM_DIALOG_DEFAULT_VALUES,
  DIALOG_DEFAULT_BREAKPOINTS,
  DIALOG_DEFAULT_STYLE,
} from '@components/confirm-dialog/confirm-dialog.helper'
import { ConfirmationService } from 'primeng/api'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import * as _ from 'lodash'
import { ConfirmDialogService } from './confirm-dialog.service'
import { DialogBasicValues, DialogPosition } from '@models/dialog.types'

const CONFIRM_DIALOG_Z_INDEX = 1200

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>()
  private readonly defaultOptions: DialogBasicValues = _.mapValues(CONFIRM_DIALOG_DEFAULT_VALUES, value =>
    this.translate.instant(value),
  )
  readonly defaultBreakpoints = DIALOG_DEFAULT_BREAKPOINTS
  readonly defaultStyle = DIALOG_DEFAULT_STYLE
  readonly zIndex = CONFIRM_DIALOG_Z_INDEX
  position: DialogPosition = 'center'

  constructor(
    private readonly confirmDialog: ConfirmDialogService,
    private readonly confirmation: ConfirmationService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.listenForOpenEvent()
    this.listenForCloseEvent()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  private listenForOpenEvent(): void {
    this.confirmDialog.open$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.position = res.position ?? 'center'

      this.confirmation.confirm({ ...this.defaultOptions, ...res })
    })
  }

  private listenForCloseEvent(): void {
    this.confirmDialog.close$.pipe(takeUntil(this.destroy$)).subscribe(() => this.confirmation.close())
  }
}
