import { format, isSameDay, isToday, isTomorrow, parse, startOfDay } from 'date-fns'
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from './general.helper'

export const parseDate = (value: string, formatString = DEFAULT_DATE_FORMAT): Date =>
  parse(value, formatString, new Date())
export const formatDate = (date: Date, formatString = DEFAULT_DATE_FORMAT): string => format(date, formatString)
export const formatTime = (date: Date, timeFormat = DEFAULT_TIME_FORMAT): string => format(date, timeFormat)

export const isStringDaySame = (value: string, day: Date): boolean => {
  const itemDay = startOfDay(new Date(value))

  return isSameDay(day, itemDay)
}

export const isDateToday = (date: Date) => isToday(date)

export const isDateTomorrow = (date: Date) => isTomorrow(date)
