/* Breakpoints have to be consistent with values from breakpoints.scss file */

/* Values for toast responsiveness */

export enum Breakpoint {
  XS = '(max-width: 576px)',
  SM = '(min-width: 576.02px) and (max-width: 768px)',
  MD = '(min-width: 768.02px) and (max-width: 992px)',
  LG = '(min-width: 992.02px) and (max-width: 1200px)',
  XL = '(min-width: 1200.02px)',
}

export interface CdkMatchingBreakpoints {
  [key: string]: boolean
}

export interface MatchingBreakpoint {
  breakpoint: Breakpoint
  matches: boolean
}

export type BreakpointDirectiveMode = 'include' | 'exclude'
