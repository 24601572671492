import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { MENU_ITEMS } from '../menu.helper'

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent {
  // constructor(){
  //   console.log(MENU_ITEMS)
  // }

  readonly menuItems = MENU_ITEMS.map(item => ({
    ...item,
    label: this.translate.instant(item.label ?? ''),
    icon: item.icon ?? '',
  }))

  constructor(private readonly translate: TranslateService) {
    console.log(MENU_ITEMS)
  }
}
