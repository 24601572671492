export interface ApiErrorDto {
  status: number
  message: string
  error?: ApiErrorDetailsDto | ApiValidationErrorDto
}

export interface ApiErrorDetailsDto {
  timestamp: string
  error: string
  errorDetails?: string | ApiBankIdError
}

export interface ApiValidationErrorDto extends ApiErrorDetailsDto {
  globalError?: string
  fieldErrors: ApiValidationErrorMsgDto[]
}

export interface ApiValidationErrorMsgDto {
  field: string
  msgKey: string
  msgArgs?: string[]
}

export enum ApiBankIdError {
  BANK_ID_INVALID_PARAMETERS = 'BANK_ID_INVALID_PARAMETERS',
  BANK_ID_SERVER_ERROR = 'BANK_ID_SERVER_ERROR',
  LOGIN_BANK_ID_RFA1 = 'login_bankid_RFA1',
  LOGIN_BANK_ID_RFA2 = 'login_bankid_RFA2',
  LOGIN_BANK_ID_RFA6 = 'login_bankid_RFA6',
  LOGIN_BANK_ID_RFA9 = 'login_bankid_RFA9',
  LOGIN_BANK_ID_RFA14_A = 'login_bankid_RFA14_A',
}

export enum ApiErrorStatus {
  SERVER_ERROR = 500,
  GENERAL_ERROR = 409,
  ACCESS_DENIED = 403,
}

export enum ApiGeneralErrorEnum {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}
