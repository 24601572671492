import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { extractTouchedChanges } from '@components/form-field/form-field.helper'
import { get } from 'lodash'
import { merge, Observable, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { ErrorMessages } from './inline-errors.types'

@Component({
  selector: 'app-inline-errors',
  styleUrls: ['./inline-errors.component.scss'],
  template: `
    <div *ngIf="isError" class="form-field-error">
      {{ finalMessage }}
    </div>
  `,
})
export class InlineErrorsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>()
  private errorKeys: string[] = []
  translatedMessages: string[] = []
  finalMessage = ''
  localField?: FormControl

  @Input()
  form!: FormGroup
  @Input()
  fieldName!: string
  @Input()
  errorMessages?: ErrorMessages

  @Output()
  readonly errors = new EventEmitter<boolean>(false)

  constructor(private readonly translate: TranslateService) {}

  get isError(): boolean | null | undefined {
    return this.localField?.errors && this.localField.invalid && (this.localField.touched || this.localField.dirty)
  }

  ngOnInit(): void {
    this.localField = this.form.get(this.fieldName) as FormControl | undefined

    if (!this.localField) {
      return
    }

    const valueChanges$ = this.localField.valueChanges
    const touchChanges$ = extractTouchedChanges(this.localField)

    this.listenForFieldChanges(valueChanges$, touchChanges$)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  private listenForFieldChanges(valueChanges$: Observable<any>, touchChanges$: Observable<boolean>): void {
    merge(valueChanges$, touchChanges$)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !!this.localField),
      )
      .subscribe(() => {
        this.translatedMessages = []

        if (this.localField?.errors) {
          this.errorKeys = Object.keys(this.localField.errors)
          this.mapErrorMessages()
        }

        this.errors.emit(!!this.localField?.errors)
      })
  }

  private mapErrorMessages(): void {
    this.errorKeys.forEach(key => {
      const message = this.getTranslatedMessage(key)

      this.translatedMessages.push(message)
    })

    if (this.translatedMessages.length === 1) {
      this.finalMessage = this.translatedMessages[0]

      return
    }

    const joinedMessages = this.translatedMessages.join(', ').toLowerCase()

    this.finalMessage = joinedMessages.charAt(0).toUpperCase() + joinedMessages.slice(1)
  }

  private getTranslatedMessage(key: string): any {
    const fieldMessage = get(this.errorMessages, `${this.fieldName}.${key}`)

    return fieldMessage ?? this.translate.instant(`inlineErrors.${key}`)
  }
}
