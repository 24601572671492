import { Pipe, PipeTransform } from '@angular/core'
import { GenderEnum } from '@models/patient.types'

const MALE_SRC = 'assets/icons/male.svg'
const FEMALE_SRC = 'assets/icons/female.svg'

@Pipe({
  name: 'genderIcon',
})
export class GenderIconPipe implements PipeTransform {
  transform(gender: GenderEnum): string {
    return gender === GenderEnum.Male ? MALE_SRC : FEMALE_SRC
  }
}
