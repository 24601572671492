import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core'
import * as _ from 'lodash'

export type ListSelectionMode = 'checkboxes' | 'border'

@Component({
  selector: 'app-selection-list [data] [itemTemplate]',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss'],
})
export class SelectionListComponent<T> {
  @Input()
  data!: Array<T>

  @Input()
  itemTemplate!: TemplateRef<any>

  @Input()
  multiselect = false

  @Input()
  selectedData: T[] = []

  @Input()
  vertical: boolean = false

  @Input()
  selectionMode: ListSelectionMode = 'checkboxes'

  @Input()
  divided = true

  @Input()
  unselectable = true

  @Input()
  comparator?: (item: T) => boolean

  @Output()
  readonly selectionChange = new EventEmitter<T[]>()

  itemClicked(clickedItem: T): void {
    if (this.multiselect) {
      this.addOrRemoveItemMultiSelect(clickedItem)
    } else {
      this.addOrRemoveItemSingle(clickedItem)
    }
    this.selectionChange.emit(this.selectedData)
  }

  private addOrRemoveItemMultiSelect(clickedItem: T) {
    const foundItemIndex = this.selectedData!.findIndex(item => _.isEqual(item, clickedItem))
    if (foundItemIndex > -1) {
      if (this.unselectable) {
        this.selectedData?.splice(foundItemIndex, 1)
      }
    } else {
      this.selectedData?.push(clickedItem)
    }
    this.selectedData = [...this.selectedData!]
  }

  private addOrRemoveItemSingle(clickedItem: T) {
    if (_.isEqual(this.selectedData[0], clickedItem)) {
      if (this.unselectable) {
        this.selectedData = []
      }
    } else {
      this.selectedData = [clickedItem]
    }
  }
}
