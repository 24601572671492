import { DialogBasicValues } from '@models/dialog.types'
import { DialogStyle } from '@models/dialog.types'

export const CONFIRM_DIALOG_DEFAULT_VALUES: DialogBasicValues = {
  header: 'general.confirm',
  message: 'general.confirmationQuestion',
  acceptLabel: 'general.yes',
  rejectLabel: 'general.no',
}

export const DIALOG_DEFAULT_BREAKPOINTS: Record<string, string> = {
  '1200px': '70vw',
  '768px': '100vw',
}
export const DIALOG_DEFAULT_STYLE: DialogStyle = {
  width: '40vw',
}
