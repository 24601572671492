import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Control } from '@models/form-controls-array.types'

@Component({
  template: '',
})
export abstract class InputControlAbstractComponent {
  @Input()
  form!: FormGroup

  @Input()
  control!: Control

  /**
   * @type string
   * @example 'general.'
   */
  @Input()
  translationKeyPrefix: string = 'general.'

  @Input()
  abstract maxLength: number
}
