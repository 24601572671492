import { APP_INITIALIZER, Injector, NgModule } from '@angular/core'
import { BrowserModule, Title } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { RouterModule } from '@angular/router'
import { SharedModule } from './shared/shared.module'
import { AppComponent } from './app.component'
import { appRoutes } from './app.routes'
import { EnvironmentService } from '@services/environment/environment.service'
import { translateInitializerFactory } from '@helpers/translate.helper'
import { MessageService } from 'primeng/api'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { NgProgressModule } from 'ngx-progressbar'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}
// , ProfileComponent, HelthProfileComponent, AdressesComponent, PatientsComponent, NotificationsComponent, SubscriptionComponent, BillingComponent, VisaComponent, ContactComponent,AccountComponent
@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgProgressModule.withConfig({
      thick: true,
      // Same as $secondary-color from variables.scss
      color: '#ff005c',
    }),
    MatButtonModule,
    MatIconModule,
  ],
  providers: [
    Title,
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: translateInitializerFactory,
      deps: [TranslateService, EnvironmentService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
