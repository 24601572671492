import { Injectable } from '@angular/core'
import { NationalitiesApiService } from '@services/nationalities/nationalities-api.service'
import { map, shareReplay } from 'rxjs/operators'
import { Observable } from 'rxjs'

const UNDEFINED_STRING = 'UNDEFINED'

@Injectable({
  providedIn: 'root',
})
export class NationalitiesService {
  nationalities$ = this.nationalitiesApi.getNationalities().pipe(
    map(({ list = [] }) => list.filter(({ alpha2Code }) => alpha2Code !== UNDEFINED_STRING)),
    shareReplay(1),
  )

  private mappedNationalities$ = this.nationalities$.pipe(
    map(
      nationalities =>
        new Map<string, string>(nationalities!.map(({ alpha2Code, countryName }) => [alpha2Code, countryName])),
    ),
    shareReplay(1),
  )

  constructor(private readonly nationalitiesApi: NationalitiesApiService) {}

  getName(countryCode: string): Observable<string | undefined> {
    return this.mappedNationalities$.pipe(map(nationalities => nationalities.get(countryCode)))
  }
}
