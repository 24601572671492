import { Pipe, PipeTransform } from '@angular/core'
import { NamedId } from '@models/general.types'

@Pipe({
  name: 'names',
})
export class NamesPipe implements PipeTransform {
  transform(products: NamedId[]): string[] {
    return products.map(product => product.name)
  }
}
