import { Pipe, PipeTransform } from '@angular/core'
import { GenericUser } from '@models/general.types'

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform({ firstName = '', lastName = '' }: GenericUser): string {
    return `${firstName} ${lastName}`.trim()
  }
}
