import { Injectable } from '@angular/core'
import { StorageEngineService } from './storage-engine.service'

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private readonly service: StorageEngineService) {}

  public set(key: string, item: any): void {
    this.service.set(key, item)
  }

  public get<T>(key: string): T {
    return this.service.get(key) as T
  }

  public delete(key: string): void {
    this.service.delete(key)
  }

  public getAll(): any {
    return this.service.getAll()
  }
}
