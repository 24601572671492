import { Pipe, PipeTransform } from '@angular/core'
import { ICONS_PATH } from '@helpers/general.helper'

const DEFAULT_ICON_SOURCE = '.svg'

@Pipe({
  name: 'iconSource',
})
export class IconSourcePipe implements PipeTransform {
  transform(name: string, format = DEFAULT_ICON_SOURCE): string {
    if (name.includes('cancelled')) {
      name = name.split('-')[0]
    }
    return ICONS_PATH + name + format
  }
}
