import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ButtonType } from '@models/general.types'
import { ButtonIconPlacement } from './progress-button.types'

const DEFAULT_BUTTON_CLASSES = 'progress-button'
const DEFAULT_BUTTON_TYPE: ButtonType = 'submit'

@Component({
  selector: 'app-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss'],
})
export class ProgressButtonComponent {
  finalClasses = DEFAULT_BUTTON_CLASSES

  @Input()
  type: ButtonType = DEFAULT_BUTTON_TYPE
  @Input()
  loading = false
  @Input()
  disabled = false
  @Input()
  iconPath = ''
  @Input()
  iconPlacement: ButtonIconPlacement = 'left'

  @Output()
  readonly clicked = new EventEmitter<Event>()

  @Input()
  set classes(value: string) {
    this.finalClasses = `${DEFAULT_BUTTON_CLASSES} ${value}`
  }

  onClicked($event: Event): void {
    this.clicked.emit($event)
  }
}
