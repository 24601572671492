<div [class.vertical-layout]="vertical" class="mb-4">
  <ng-container *ngFor="let item of data; let last = last">
    <div
      class="flex-center-y cursor-pointer selection-item"
      [ngClass]="{
        'selection-item-vertical': vertical,
        'selection-item-border': selectionMode === 'border',
        'selection-item-border-selected':
          selectionMode === 'border' && selectedData | selectionListChecked: item:comparator,
        'mb-2': !divided
      }"
      (click)="itemClicked(item)"
    >
      <input
        *ngIf="selectionMode === 'checkboxes'"
        type="checkbox"
        class="p-checkbox mr-4 item-checkbox"
        [checked]="selectedData | selectionListChecked: item"
      />
      <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
    <p-divider *ngIf="!last && !vertical && divided"></p-divider>
  </ng-container>
</div>
