import { Pipe, PipeTransform } from '@angular/core'

const DEFAULT_SEPARATOR = ', '

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(array: any[], separator = DEFAULT_SEPARATOR): string {
    return array.join(separator)
  }
}
