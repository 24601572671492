import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { AuthService } from '@services/auth/auth.service'

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly auth: AuthService) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const { isLogged } = this.auth
    const { url } = state

    if (!isLogged) {
      this.router.navigateByUrl('/login', { state: { previousUrl: url } })
    }

    return isLogged
  }
}
