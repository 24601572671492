import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { AuthService } from '@services/auth/auth.service'

@Injectable()
export class NotLoggedInGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly auth: AuthService) {}

  canActivate(): boolean {
    const { isLogged } = this.auth

    if (isLogged) {
      this.router.navigateByUrl('/home')
    }

    return !isLogged
  }
}
