import { Component } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper'
import { CropperConfig } from '@components/image-cropper-modal/image-cropper.types'
import { ToastService } from '@services/toast/toast.service'
import { TranslateService } from '@ngx-translate/core'
import { FileEvent } from '@helpers/file-events.helper.'

interface CropperZoom {
  maxZoom: number
  minZoom: number
  zoomStep: number
}

const ZOOM_CONFIG: CropperZoom = {
  maxZoom: 5,
  minZoom: 1,
  zoomStep: 0.5,
}

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent {
  private imageB64!: string | null | undefined

  config: CropperConfig = this.dialog.data?.config
  fileEvent: FileEvent = this.dialog.data.image

  loading = true
  zoom = 1
  cropperTransform: ImageTransform = {
    scale: this.zoom,
  }

  readonly zoomConfig = ZOOM_CONFIG

  constructor(
    private readonly dialog: DynamicDialogConfig,
    private readonly dialogService: DynamicDialogRef,
    private readonly toast: ToastService,
    private readonly translate: TranslateService,
  ) {}

  saveImage(): void {
    if (!this.imageB64) {
      this.onImageLoadFailed()
      return
    }
    fetch(this.imageB64)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], this.fileEvent.target!.files[0].name, { type: 'image/png' })
        this.dialogService.close(file)
      })
  }

  onImageCropped(image: ImageCroppedEvent): void {
    this.imageB64 = image.base64
  }

  onImageLoadFailed(): void {
    this.toast.error(this.translate.instant('settings.profile.imageCropFailed'))
    this.dialogService.close()
  }

  onImageLoaded() {
    this.loading = false
  }

  onZoomChange() {
    this.cropperTransform = { ...this.cropperTransform, scale: this.zoom }
  }
}
