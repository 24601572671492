import { Pipe, PipeTransform } from '@angular/core'
import { parseDate } from '@helpers/date-time.helper'
import { API_DATE_FORMAT } from '@helpers/general.helper'
import { differenceInYears } from 'date-fns'

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform({ birthDate }: { birthDate?: string }): number {
    const formattedDate = parseDate(birthDate!, API_DATE_FORMAT)
    return differenceInYears(new Date(), formattedDate)
  }
}
