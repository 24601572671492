import { Pipe, PipeTransform } from '@angular/core'
import { SocialSecurityNumberResponseDto } from '@models/api/socialSecurityNumberResponseDto'
import { NationalitiesService } from '@services/nationalities/nationalities.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Pipe({
  name: 'personalData$',
})
export class PersonalDataPipe implements PipeTransform {
  constructor(private readonly nationalities: NationalitiesService) {}

  transform({ socialSecurityNumber, nationality }: SocialSecurityNumberResponseDto): Observable<string> {
    return this.nationalities
      .getName(nationality)
      .pipe(map((countryName = '') => `${socialSecurityNumber}, ${countryName}`))
  }
}
