import { Component, Input } from '@angular/core'
import { DocumentResponseDto } from '@models/api/documentResponseDto'
import { GenericUser } from '@models/general.types'

@Component({
  selector: 'app-document-item [document] [patient]',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
})
export class DocumentItemComponent {
  @Input()
  document!: DocumentResponseDto
  @Input()
  patient!: GenericUser
}
