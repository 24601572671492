// Solution from: https://newbedev.com/how-to-observe-touched-event-on-angular-2-ngform

import { AbstractControl } from '@angular/forms'
import { Observable, Subject } from 'rxjs'

export type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never
type ObjectLike<O extends object, P extends keyof O = keyof O> = Pick<O, P>
type ActionType = 'markAsTouched' | 'markAsUntouched'

export const extractTouchedChanges = (control: ObjectLike<AbstractControl, ActionType>): Observable<boolean> => {
  const prevMarkAsTouched = control.markAsTouched
  const prevMarkAsUntouched = control.markAsUntouched

  const touchedChanges$ = new Subject<boolean>()

  function nextMarkAsTouched(...args: ArgumentsType<AbstractControl['markAsTouched']>) {
    touchedChanges$.next(true)
    prevMarkAsTouched.bind(control)(...args)
  }

  function nextMarkAsUntouched(...args: ArgumentsType<AbstractControl['markAsUntouched']>) {
    touchedChanges$.next(false)
    prevMarkAsUntouched.bind(control)(...args)
  }

  control.markAsTouched = nextMarkAsTouched
  control.markAsUntouched = nextMarkAsUntouched

  return touchedChanges$
}
