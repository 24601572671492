import { Component, Input } from '@angular/core'
import { EMPTY_VALUE_PLACEHOLDER } from '@helpers/general.helper'

@Component({
  selector: 'app-details-section [headerKey]',
  templateUrl: './details-section.component.html',
  styleUrls: ['./details-section.component.scss'],
})
export class DetailsSectionComponent {
  readonly emptyValuePlaceholder = EMPTY_VALUE_PLACEHOLDER

  @Input()
  headerKey!: string
}
