import { Injectable, OnDestroy } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { ConfirmDialogService } from '@components/confirm-dialog/confirm-dialog.service'
import { BeforeInstallPromptEvent, UserChoice } from '@models/ngsw.types'
import { TranslateService } from '@ngx-translate/core'
import { BroadcastChannelMessageType } from '@services/broadcast-channel/broadcast-channel.types'
import { StorageService } from '@services/storage/storage.service'
import { from, fromEvent, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AppUpdateBroadcastService } from './app-update-broadcast.service'

const INSTALLATION_REJECTED_KEY = 'installationRejected'

@Injectable()
export class AppPwaService implements OnDestroy {
  private readonly destroy$ = new Subject<void>()
  private installationEvent?: BeforeInstallPromptEvent
  activateUpdate = (): Promise<void> => this.updateVersion()

  constructor(
    private readonly confirmDialog: ConfirmDialogService,
    private readonly translate: TranslateService,
    private readonly swUpdate: SwUpdate,
    private readonly appUpdateBroadcast: AppUpdateBroadcastService,
    private readonly storage: StorageService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  get installationRejected(): boolean {
    return !!this.storage.get<boolean | undefined>(INSTALLATION_REJECTED_KEY)
  }

  set installationRejected(value: boolean) {
    this.storage.set(INSTALLATION_REJECTED_KEY, value)
  }

  listenForUpdateEvent(): void {
    this.swUpdate.available.pipe(takeUntil(this.destroy$)).subscribe(() => this.handleUpdatePrompt())
  }

  private async updateVersion(): Promise<void> {
    return this.swUpdate.activateUpdate().then(() => document.location.reload())
  }

  private handleUpdatePrompt(): void {
    if (!this.swUpdate.isEnabled) {
      return
    }

    this.confirmDialog.open({
      position: 'bottom',
      icon: 'pi pi-refresh',
      header: this.translate.instant('pwa.updateHeader'),
      message: this.translate.instant('pwa.updateQuestion'),
      accept: () => this.onUpdateAccepted(),
    })
  }

  private onUpdateAccepted(): void {
    this.appUpdateBroadcast.broadcastMessageByType(BroadcastChannelMessageType.UPDATE_APP)

    this.activateUpdate()
  }

  listenForInstallationEvent(): void {
    fromEvent<BeforeInstallPromptEvent>(window, 'beforeinstallprompt')
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => this.handleInstallPropmt(event))
  }

  private handleInstallPropmt(event: BeforeInstallPromptEvent): void {
    if (this.installationRejected) {
      return
    }

    this.installationEvent = event

    this.confirmDialog.open({
      position: 'top',
      icon: 'pi pi-download',
      header: this.translate.instant('pwa.installationHeader'),
      message: this.translate.instant('pwa.installationQuestion'),
      accept: () => this.onInstallationAccepted(),
      reject: () => this.onInstallationRejected(),
    })
  }

  private onInstallationAccepted(): void {
    if (!this.installationEvent) {
      return
    }

    this.installationEvent.prompt()

    from(this.installationEvent.userChoice)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ outcome }) => {
        if (outcome === UserChoice.DISMISSED) {
          this.onInstallationRejected()
        }

        this.installationEvent = undefined
      })
  }

  private onInstallationRejected(): void {
    this.installationRejected = true
  }
}
