import { Component, OnDestroy, OnInit } from '@angular/core'
import { AuthLogoutService } from '@services/auth/auth-logout.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
})
export class LogoutButtonComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>()
  pending = false

  constructor(private readonly authLogout: AuthLogoutService) {}

  ngOnInit(): void {
    this.listenForLogoutSubmit()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  private listenForLogoutSubmit(): void {
    this.authLogout.listener$.pipe(takeUntil(this.destroy$)).subscribe(() => (this.pending = true))
  }

  openLogoutConfirmDialog(): void {
    this.authLogout.openLogoutConfirmDialog()
  }
}
