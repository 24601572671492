import { trigger, animate, transition, style, query } from '@angular/animations'

const TRANSITION_TIME = '0.15s'

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(':leave', [style({ opacity: 1 }), animate(TRANSITION_TIME, style({ opacity: 0 }))], { optional: true }),
    query(':enter', [style({ opacity: 0 }), animate(TRANSITION_TIME, style({ opacity: 1 }))], { optional: true }),
  ]),
])
