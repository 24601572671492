import { Injectable } from '@angular/core'
import { DialogOptions } from '@models/dialog.types'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  readonly open$ = new Subject<DialogOptions>()
  readonly close$ = new Subject<any>()

  open(options: DialogOptions = {}): void {
    this.open$.next(options)
  }

  close(): void {
    this.close$.next()
  }
}
