<div
  *ngFor="let item of menuItems"
  class="bottom-bar-menu-item"
  routerLinkActive="route-active"
  [routerLink]="item.routerLink"
>
  <svg-icon [src]="item.icon"> </svg-icon>

  <span class="mt-1">{{ item.label | translate }}</span>
</div>
