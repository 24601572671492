<div class="patient-gender">
  <svg-icon [src]="patient.avatarUrl ?? (patient.gender! | genderIcon)"></svg-icon>
</div>

<div class="patient-details">
  <div *ngIf="patient.hasWarning || patient.warnings" class="patient-warning">
    {{ 'general.warning' | translate }}
  </div>

  <div class="patient-name">
    {{ anonymous ? (patient | genderWithAge) : (patient | fullNameWithAge) }}
  </div>

  <div *ngIf="showPersonalData && patient.socialSecurityNumberData" class="personal-data">
    {{ patient.socialSecurityNumberData | personalData$ | async }}
  </div>

  <div *ngIf="patient.products" class="patient-products">
    {{ patient.products | names | join }}
  </div>

  <div *ngIf="patient.productsNames" class="patient-products">
    {{ patient.productsNames | join }}
  </div>
</div>

<div class="ml-auto flex-center-y">
  <ng-container [ngTemplateOutlet]="itemEndTemplate!" [ngTemplateOutletContext]="{ $implicit: patient }"></ng-container>

  <div *ngIf="clickable" class="ml-4">
    <svg-icon class="flex-center-y" src="assets/icons/go-arrow.svg"></svg-icon>
  </div>
</div>
