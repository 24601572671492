import { Injectable } from '@angular/core'
import { EnvironmentService } from '@services/environment/environment.service'
import { tryParseJSON } from '@helpers/json.helper'
import { StorageEngine } from './storage.types'

@Injectable({
  providedIn: 'root',
})
export class StorageEngineService implements StorageEngine {
  prefix: string

  constructor(private readonly environment: EnvironmentService) {
    this.prefix = this.environment.uniqueIdentifier

    if (!window.localStorage) {
      throw new Error('This device do not provide LocalStorage')
    }
  }

  set(key: string, value: any): void {
    window.localStorage.setItem(this.mapKey(key), JSON.stringify(value))
  }

  get(key: string): any {
    const value = window.localStorage.getItem(this.mapKey(key))

    if (!value) {
      return
    }

    return tryParseJSON(value)
  }

  delete(key: string): void {
    window.localStorage.removeItem(this.mapKey(key))
  }

  getAll(): any {
    const result: any = {}

    Object.keys(window.localStorage)
      .filter(item => item.startsWith(this.prefix))
      .forEach(key => {
        const value = tryParseJSON(window.localStorage.getItem(key))

        result[key.split(`${this.prefix}-`)[1]] = value
      })

    return result
  }

  private mapKey(key: string): string {
    return `${this.prefix}-${key}`
  }
}
