<ng-container *ngIf="items?.length; else placeholderTemplate">
  <ng-container *ngIf="itemTemplate; else joinTemplate">
    <div *ngFor="let item of items; let last = last" class="my-3" [class.flex-center-between]="deletable">
      <ng-container
        [ngTemplateOutlet]="itemTemplate"
        [ngTemplateOutletContext]="{
          $implicit: item,
          last: last
        }"
      ></ng-container>

      <svg-icon
        *ngIf="deletable"
        class="flex-center-y cursor-pointer"
        src="assets/icons/delete.svg"
        (click)="onDeleteClicked(item)"
      ></svg-icon>
    </div>
  </ng-container>
</ng-container>

<ng-template #joinTemplate>
  <span [class]="joinClass">{{ items! | join: joinSeparator }}</span>
</ng-template>

<ng-template #placeholderTemplate>
  <small>{{ 'general.noResultsFound' | translate }}</small>
</ng-template>
