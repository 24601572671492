import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { SECONDS_IN_MINUTE } from '@services/user-idle/user-idle.helper'
import { DynamicDialogRef } from 'primeng/dynamicdialog'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { InactivityModalCloseResult } from '../layout.types'
import { InactivityModalBroadcastService } from './inactivity-dialog-broadcast.service'

@Component({
  selector: 'app-inactivity-dialog',
  templateUrl: './inactivity-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InactivityModalBroadcastService],
})
export class InactivityDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>()
  // TODO: use async pipe
  secondsLeft = SECONDS_IN_MINUTE

  constructor(
    private readonly dialogRef: DynamicDialogRef,
    private readonly inactivityModalbroadcast: InactivityModalBroadcastService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.runListeners()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.inactivityModalbroadcast.clearListener()
  }

  private runListeners(): void {
    this.listenForTick()
    this.listenForClose()

    this.inactivityModalbroadcast.runDelayOrMessagesListener()
  }

  private listenForTick(): void {
    this.inactivityModalbroadcast.tick$.pipe(takeUntil(this.destroy$)).subscribe(secondsLeft => {
      this.secondsLeft = secondsLeft

      this.changeDetector.detectChanges()
    })
  }

  private listenForClose(): void {
    this.inactivityModalbroadcast.close$.pipe(takeUntil(this.destroy$)).subscribe(idleError => this.close(idleError))
  }

  close(idleError: boolean): void {
    const result: InactivityModalCloseResult = {
      resetTimer: false,
      idleError,
    }

    this.dialogRef.close(result)
  }

  dismiss(): void {
    const result: InactivityModalCloseResult = {
      resetTimer: true,
    }

    this.dialogRef.close(result)
  }
}
