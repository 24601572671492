export const DEFAULT_DATE_FORMAT = 'd-M-yyyy'
export const DEFAULT_TIME_FORMAT = 'HH:mm'
export const DEFAULT_DISTANCE_UNIT = 'km'
export const DEFAULT_DEBOUNCE_TIME = 300
export const DEFAULT_PAGE_SIZE = 10

export const API_DATE_FORMAT = 'yyyy-M-d'

export const ICONS_PATH = 'assets/icons/'

export const EMPTY_VALUE_PLACEHOLDER = '-'

export const TEXT_AREA_MAX_LENGTH = 1000
