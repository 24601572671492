import { Pipe, PipeTransform } from '@angular/core'
import { NationalitiesService } from '@services/nationalities/nationalities.service'
import { Observable } from 'rxjs'

@Pipe({
  name: 'nationality$',
})
export class NationalityPipe implements PipeTransform {
  constructor(private nationalities: NationalitiesService) {}

  transform(countryCode = ''): Observable<string | undefined> {
    return this.nationalities.getName(countryCode)
  }
}
