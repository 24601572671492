import { Component, Input, TemplateRef } from '@angular/core'
import { GenericUser } from '@models/general.types'

@Component({
  selector: 'app-patient-row [patient]',
  templateUrl: './patient-row.component.html',
  styleUrls: ['./patient-row.component.scss'],
})
export class PatientRowComponent {
  @Input()
  patient!: GenericUser
  @Input()
  clickable = false
  @Input()
  anonymous = false
  @Input()
  showPersonalData = true
  @Input()
  itemEndTemplate?: TemplateRef<any>
}
