import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'

interface VarDirectiveContext {
  $implicit?: any
  appVar?: any
}

@Directive({
  selector: '[appVar]',
})
export class VarDirective {
  context: VarDirectiveContext = {}

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  @Input()
  set appVar(value: any) {
    this.context.$implicit = this.context.appVar = value

    this.updateView()
  }

  updateView() {
    this.viewContainer.clear()
    this.viewContainer.createEmbeddedView(this.templateRef, this.context)
  }
}
