<div [formGroup]="form" class="w-100">
  <app-form-field [form]="form" [field]="control.controlName" class="w-100">
    <label>
      {{ translationKeyPrefix + control.controlName | translate }}

      <input
        [formControlName]="control.controlName"
        [id]="control.controlName"
        pInputText
        type="text"
        class="w-100"
        [maxlength]="maxLength"
        [placeholder]="control.placeholder | translate"
      />
    </label>
  </app-form-field>
</div>
