import { Injectable } from '@angular/core'
import { EnvironmentService } from '@services/environment/environment.service'
import { BroadcastChannel, createLeaderElection, LeaderElector } from 'broadcast-channel'
import { Subject } from 'rxjs'
import { BroadcastChannelMessage, BroadcastChannelMessageType } from './broadcast-channel.types'

export const CHANNEL_SUFFIX = '-BC'

@Injectable({
  providedIn: 'root',
})
export class BroadcastChannelService {
  private channel?: BroadcastChannel<BroadcastChannelMessage>
  private elector?: LeaderElector
  private readonly channelName = this.environment.uniqueIdentifier + CHANNEL_SUFFIX
  private readonly message$ = new Subject<BroadcastChannelMessage>()
  readonly messages$ = this.message$.asObservable()
  leader = false

  constructor(private readonly environment: EnvironmentService) {}

  open(): void {
    this.channel = new BroadcastChannel<BroadcastChannelMessage>(this.channelName)
    this.channel.onmessage = message => this.message$.next(message)

    const elector = createLeaderElection(this.channel)

    elector.awaitLeadership().then(() => (this.leader = true))
  }

  broadcastMessage(message: BroadcastChannelMessage): void {
    this.channel?.postMessage(message)
  }

  broadcastMessageByType(type: BroadcastChannelMessageType): void {
    this.channel?.postMessage({ type })
  }

  close(): void {
    this.elector?.die()
    this.channel?.close()
  }
}
