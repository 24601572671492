<p-button [styleClass]="finalClasses" [type]="type" [disabled]="disabled" (onClick)="onClicked($event)">
  <ng-template pTemplate="content">
    <ng-container
      *ngIf="iconPlacement === 'left'"
      [ngTemplateOutlet]="icon"
      [ngTemplateOutletContext]="{ loading, iconPath }"
    ></ng-container>

    <span [class]="iconPlacement === 'left' ? 'ml-2' : 'mr-2'">
      <ng-content></ng-content>
    </span>

    <ng-container
      *ngIf="iconPlacement === 'right'"
      [ngTemplateOutlet]="icon"
      [ngTemplateOutletContext]="{ loading, iconPath }"
    ></ng-container>
  </ng-template>
</p-button>

<ng-template #icon let-loading="loading" let-iconPath="iconPath">
  <ng-container *ngIf="!loading; else buttonSpinner">
    <svg-icon *ngIf="iconPath" [src]="iconPath"></svg-icon>
  </ng-container>

  <ng-template #buttonSpinner>
    <p-progressSpinner></p-progressSpinner>
  </ng-template>
</ng-template>
