import { Injectable } from '@angular/core'
import { HttpClientService } from '@services/http/http-client.service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private readonly http: HttpClientService) {}

  logout(): Observable<void> {
    return this.http.post('/logout')
  }
}
