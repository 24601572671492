import { Injectable } from '@angular/core'
import { NavigationBehaviorOptions, Router } from '@angular/router'

const NAVIGATE_PLACEHOLDER = '/NAVIGATE_PLACEHOLDER'
const NAVIGATE_OPTIONS: NavigationBehaviorOptions = { skipLocationChange: true }

@Injectable({
  providedIn: 'root',
})
export class SilentRouterService {
  constructor(private readonly router: Router) {
    router.config.unshift({ path: NAVIGATE_PLACEHOLDER })
  }

  async reload(): Promise<boolean> {
    const { url } = this.router

    return this.triggerNavigate(url)
  }

  async navigate(path: string): Promise<boolean> {
    return this.triggerNavigate(path)
  }

  private async triggerNavigate(path: string): Promise<boolean> {
    return this.router.navigateByUrl(NAVIGATE_PLACEHOLDER, NAVIGATE_OPTIONS).then(() => this.router.navigate([path]))
  }
}
