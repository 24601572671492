import { Inject, Injectable, InjectionToken, NgZone } from '@angular/core'
import { runInZone } from '@helpers/rxjs.helper'
import { BroadcastChannelService } from '@services/broadcast-channel/broadcast-channel.service'
import {
  BroadcastChannelMessage,
  BroadcastChannelMessageType,
} from '@services/broadcast-channel/broadcast-channel.types'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'

const MessageTypesToken = new InjectionToken('AbstractBroadcastServiceMessageTypes')

type ListenerCallbackType = void | Promise<void | boolean>

@Injectable()
export abstract class AbstractBroadcastService {
  protected readonly clear$ = new Subject<void>()

  constructor(
    @Inject(MessageTypesToken) protected readonly messageTypes: BroadcastChannelMessageType[],
    protected readonly broadcastChannel: BroadcastChannelService,
    protected readonly ngZone: NgZone,
  ) {}

  runListener(callback: (res: BroadcastChannelMessage) => ListenerCallbackType): void {
    this.broadcastChannel.messages$
      .pipe(
        runInZone(this.ngZone),
        filter(({ type }) => this.messageTypes.includes(type)),
        takeUntil(this.clear$),
      )
      .subscribe((res: BroadcastChannelMessage) => callback(res))
  }

  clearListener(): void {
    this.clear$.next()
    this.clear$.complete()
  }

  broadcastMessageByType(type: BroadcastChannelMessageType): void {
    this.broadcastChannel.broadcastMessageByType(type)
  }
}
