import { Message } from 'primeng/api'

export enum MessageType {
  SUCCESS = 'success',
  WARN = 'warn',
  INFO = 'info',
  ERROR = 'error',
  CUSTOM = 'custom',
}

export type ToastOptions = Omit<Message, 'severity' | 'summary'>
