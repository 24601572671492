import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-simple-page-with-topbar',
  templateUrl: './simple-page-with-topbar.component.html',
  styleUrls: ['./simple-page-with-topbar.component.scss'],
})
export class SimplePageWithTopbarComponent {
  @Input()
  showLogout = false
}
