import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule, DecimalPipe } from '@angular/common'
import { LoggedInGuard } from '@guards/logged-in.guard'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { HttpClientInterceptor } from '@interceptors/http-client.interceptor'
import { DigitOnlyModule } from '@uiowa/digit-only'
import { NotLoggedInGuard } from '@guards/not-logged.guard'
import { KeysPipe } from '@pipes/keys.pipe'
import { ToastModule } from 'primeng/toast'
import { ButtonModule } from 'primeng/button'
import { RippleModule } from 'primeng/ripple'
import { ToastService } from '@services/toast/toast.service'
import { LogoComponent } from '@components/logo/logo.component'
import { CardModule } from 'primeng/card'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { ProgressButtonComponent } from '@components/progress-button/progress-button.component'
import { MessageModule } from 'primeng/message'
import { LayoutComponent } from '@components/layout/layout.component'
import { TopBarComponent } from '@components/layout/top-bar/top-bar.component'
import { MenubarModule } from 'primeng/menubar'
import { RouteTransitionPipe } from '@pipes/route-transition.pipe'
import { BottomBarComponent } from '@components/layout/bottom-bar/bottom-bar.component'
import { InlineErrorsComponent } from '@components/form-field/inline-errors/inline-errors.component'
import { ServerErrorsComponent } from '@components/form-field/server-errors/server-errors.component'
import { FormFieldComponent } from '@components/form-field/form-field.component'
import { AuthTopBarComponent } from '@components/auth-topbar/auth-top-bar.component'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component'
import { ConfirmationService } from 'primeng/api'
import { PageHeaderComponent } from '@components/page-header/page-header.component'
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog'
import { InactivityDialogComponent } from '@components/layout/inactivity-modal/inactivity-dialog.component'
import { LogoutButtonComponent } from '@components/logout-button/logout-button.component'
import { NationalityPipe } from '@pipes/nationality.pipe'
import { ImageCropperModalComponent } from '@components/image-cropper-modal/image-cropper-modal.component'
import { ImageCropperModule } from 'ngx-image-cropper'
import { DialogModule } from 'primeng/dialog'
import { LayoutModule } from '@angular/cdk/layout'
import { PageWithHeaderComponent } from '@components/page-with-header/page-with-header.component'
import { ProgressSpinnerComponent } from '@components/progress-spinner/progress-spinner.component'
import { InputTextModule } from 'primeng/inputtext'
import { DividerModule } from 'primeng/divider'
import { SliderModule } from 'primeng/slider'
import { FirstLetterPipe } from '@pipes/first-letter.pipe'
import { ScheduleRangeTimePipe } from '@pipes/schedule-range-time.pipe'
import { FullNameWithAgePipe } from '@pipes/full-name-with-age.pipe'
import { GenderIconPipe } from '@pipes/gender-icon.pipe'
import { PatientListComponent } from '@components/patient-list/patient-list.component'
import { JoinPipe } from '@pipes/join.pipe'
import { BreakpointDirective } from '@directives/breakpoint.directive'
import { KebabCasePipe } from '@pipes/kebab-case.pipe'
import { VarDirective } from '@directives/var.directive'
import { IconSourcePipe } from '@pipes/icon-source.pipe'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { CalendarModule } from 'primeng/calendar'
import { SelectionListComponent } from '@components/selection-list/selection-list.component'
import { CheckboxModule } from 'primeng/checkbox'
import { SelectionListCheckedPipe } from '@components/selection-list/selection-list-checked.pipe'
import { NamesPipe } from '@pipes/names.pipe'
import { ModelChangeDebouncedDirective } from '@directives/model-change-debounce.directive'
import { InputControlComponent } from '@components/input-control/input-control.component'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { PersonalDataPipe } from '@pipes/personal-data.pipe'
import { DropdownModule } from 'primeng/dropdown'
import { PatientRowComponent } from '@components/patient-row/patient-row.component'
import { RatingModule } from 'primeng/rating'
import { AgePipe } from '@pipes/age.pipe'
import { GenderPipe } from '@pipes/gender.pipe'
import { TruncateTextLongerThanPipe } from '@pipes/truncate-text-longer-than.pipe'
import { FullNamePipe } from '@pipes/full-name.pipe'
import { GenderWithAgePipe } from '@pipes/gender-with-age.pipe'
import { ReadonlyInputComponent } from '@components/readonly-input/readonly-input.component'
import { ReadonlyTextareaComponent } from '@components/readonly-textarea/readonly-textarea.component'
import { DetailsSectionComponent } from '@components/details-section/details-section.component'
import { DocumentItemComponent } from '@components/document-item/document-item.component'
import { FileNamePipe } from '@pipes/file-name.pipe'
import { FormattedListComponent } from '@components/formatted-list/formatted-list.component'
import { InputSwitchModule } from 'primeng/inputswitch'
import { TextareaControlComponent } from '@components/textarea-control/textarea-control.component'
import { ServerUnavailableDialogComponent } from '@components/server-unavailable-dialog/server-unavailable-dialog.component'
import { SimplePageWithTopbarComponent } from '@components/simple-page-with-topbar/simple-page-with-topbar.component'

const sharedPipes = [
  RouteTransitionPipe,
  FirstLetterPipe,
  ScheduleRangeTimePipe,
  FullNamePipe,
  FullNameWithAgePipe,
  JoinPipe,
  GenderIconPipe,
  KebabCasePipe,
  IconSourcePipe,
  KeysPipe,
  NationalityPipe,
  SelectionListCheckedPipe,
  PersonalDataPipe,
  AgePipe,
  GenderPipe,
  TruncateTextLongerThanPipe,
  NamesPipe,
  NationalityPipe,
  GenderWithAgePipe,
  FileNamePipe,
]

const sharedComponents = [
  LayoutComponent,
  LogoComponent,
  ProgressButtonComponent,
  LogoutButtonComponent,
  TopBarComponent,
  BottomBarComponent,
  InlineErrorsComponent,
  ServerErrorsComponent,
  FormFieldComponent,
  ConfirmDialogComponent,
  AuthTopBarComponent,
  PageHeaderComponent,
  ImageCropperModalComponent,
  InactivityDialogComponent,
  PageWithHeaderComponent,
  ProgressSpinnerComponent,
  PatientListComponent,
  SelectionListComponent,
  InputControlComponent,
  PatientRowComponent,
  ReadonlyInputComponent,
  ReadonlyTextareaComponent,
  DetailsSectionComponent,
  FormattedListComponent,
  DocumentItemComponent,
  TextareaControlComponent,
  ServerUnavailableDialogComponent,
  SimplePageWithTopbarComponent,
]

const sharedDirectives = [BreakpointDirective, ModelChangeDebouncedDirective, VarDirective]

const imports: any[] = [
  CommonModule,
  TranslateModule,
  RouterModule,
  AngularSvgIconModule.forRoot(),
  ReactiveFormsModule,
  FormsModule,
  DigitOnlyModule,
  HttpClientModule,
  ToastModule,
  CardModule,
  ButtonModule,
  RippleModule,
  ProgressSpinnerModule,
  MessageModule,
  MenubarModule,
  ConfirmDialogModule,
  DynamicDialogModule,
  ImageCropperModule,
  DialogModule,
  LayoutModule,
  InputTextModule,
  DividerModule,
  SliderModule,
  InputTextareaModule,
  CalendarModule,
  CheckboxModule,
  AutoCompleteModule,
  DropdownModule,
  RatingModule,
  InputSwitchModule,
]

@NgModule({
  imports,
  exports: [...imports, ...sharedComponents, ...sharedDirectives, ...sharedPipes],
  declarations: [...sharedComponents, ...sharedDirectives, ...sharedPipes],
  providers: [
    ...sharedPipes,
    LoggedInGuard,
    NotLoggedInGuard,
    ToastService,
    ConfirmationService,
    DialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    DecimalPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    }
  }
}
