<app-simple-page-with-topbar>
  <div class="no-network">
    <div class="icon-wrapper mb-4">
      <svg-icon src="assets/icons/lock.svg"></svg-icon>
    </div>

    <h2 class="mt-0 mb-8">{{ 'general.serverUnavailable' | translate }}</h2>

    <p-button class="w-100" styleClass="w-100" [label]="'general.retry' | translate" (click)="retry()"></p-button>
  </div>
</app-simple-page-with-topbar>
