import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core'

@Component({
  selector: 'app-formatted-list [items]',
  templateUrl: './formatted-list.component.html',
})
export class FormattedListComponent<T> {
  @Input()
  items!: T[] | null | undefined
  @Input()
  joinSeparator?: string
  @Input()
  joinClass?: string
  @Input()
  deletable = false
  @Input()
  itemTemplate?: TemplateRef<any>

  @Output()
  readonly deleteItem = new EventEmitter<T>()

  onDeleteClicked(item: T) {
    this.deleteItem.emit(item)
  }
}
