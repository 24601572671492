import { Pipe, PipeTransform } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Pipe({
  name: 'routeTransition',
})
export class RouteTransitionPipe implements PipeTransform {
  transform(activatedRoute: ActivatedRoute, isActivated: boolean): ActivatedRoute | string {
    return isActivated ? activatedRoute : ''
  }
}
