import { Injectable } from '@angular/core'
import { HttpClientService } from '@services/http/http-client.service'
import { Observable } from 'rxjs'
import { UserInfoDto } from '@models/api/userInfoDto'
import { UserAddressRequestDto } from '@models/api/userAddressRequestDto'
import { BankIdConfirmationRequestDto } from '@models/api/bankIdConfirmationRequestDto'

@Injectable({
  providedIn: 'root',
})
export class PatientApiService {
  constructor(private readonly http: HttpClientService) {}

  finalizeRegistration(dto: BankIdConfirmationRequestDto): Observable<void> {
    return this.http.post<void>('/bankid/confirm', dto)
  }

  updateAddress(dto: UserAddressRequestDto, addressId: number): Observable<void> {
    return this.http.put<void>('/addresses', { addressId, dto })
  }

  getProfile(): Observable<UserInfoDto> {
    return this.http.get<UserInfoDto>('/profile')
  }

  updateProfile(dto: UserInfoDto): Observable<UserInfoDto> {
    return this.http.put<UserInfoDto>('/profile', dto)
  }

  deleteAvatar(): Observable<void> {
    return this.http.delete<void>('/user/delete-photo')
  }

  updateAvatar(avatar: File): Observable<void> {
    const formData = new FormData()
    formData.append('avatar', avatar)
    return this.http.post<void>('/files/save-avatar', formData)
  }

  saveSignature(signature: File): Observable<void> {
    const formData = new FormData()
    formData.append('signature', signature)
    return this.http.post<void>('/user/signature', formData)
  }
}
