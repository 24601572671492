import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { AgePipe } from '@pipes/age.pipe'
import { GenericUser } from '@models/general.types'
import { GenderPipe } from './gender.pipe'
import { getAgeSuffix } from '@helpers/users.helper'

@Pipe({
  name: 'genderWithAge',
})
export class GenderWithAgePipe implements PipeTransform {
  constructor(
    private readonly gender: GenderPipe,
    private readonly translate: TranslateService,
    private readonly age: AgePipe,
  ) {}

  transform(user: GenericUser): string {
    const { age, birthDate } = user
    const gender = this.gender.transform(user)
    const finalAge = age ?? this.age.transform({ birthDate })
    const ageSuffixKey = getAgeSuffix(finalAge)
    const ageSuffix = this.translate.instant(ageSuffixKey)

    return finalAge == null ? gender : `${gender}, ${finalAge} ${ageSuffix}`
  }
}
