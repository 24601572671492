import { Injector } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LOCATION_INITIALIZED } from '@angular/common'
import { EnvironmentService } from '@services/environment/environment.service'

export function translateInitializerFactory(
  translate: TranslateService,
  environment: EnvironmentService,
  injector: Injector,
): () => Promise<void> {
  return () =>
    new Promise<void>((resolve: Function) => {
      const initialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null))

      initialized.then(() => {
        const { language } = environment

        translate.setDefaultLang(language)
        translate.use(language).subscribe(
          () => console.info(`Successfully initialized '${language}' language`),
          () => console.error(`Problem with '${language}' language initialization`),
          () => resolve(null),
        )
      })
    })
}
