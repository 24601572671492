import { Injectable, NgZone } from '@angular/core'
import { AbstractBroadcastService } from '@services/abstract-broadcast/abstract-broadcast.service'
import { BroadcastChannelService } from '@services/broadcast-channel/broadcast-channel.service'
import { BroadcastChannelMessageType } from '@services/broadcast-channel/broadcast-channel.types'

@Injectable()
export class AppUpdateBroadcastService extends AbstractBroadcastService {
  constructor(protected readonly broadcastChannel: BroadcastChannelService, protected readonly ngZone: NgZone) {
    super([BroadcastChannelMessageType.UPDATE_APP], broadcastChannel, ngZone)
  }
}
