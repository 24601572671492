import { Pipe, PipeTransform } from '@angular/core'
import { DocumentResponseDto } from '@models/api/documentResponseDto'
import { TranslateService } from '@ngx-translate/core'
import { GenericUser } from '@models/general.types'
import { FullNamePipe } from '@pipes/full-name.pipe'

@Pipe({
  name: 'fileName',
})
export class FileNamePipe implements PipeTransform {
  constructor(private readonly fullNamePipe: FullNamePipe, private readonly translate: TranslateService) {}

  transform({ type }: DocumentResponseDto, patient: GenericUser): string {
    return this.translate.instant('appointments.documents.name', {
      patientName: this.fullNamePipe.transform(patient),
      type: this.translate.instant(`appointments.documents.types.${type}`),
    })
  }
}
