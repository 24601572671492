import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'
import { ToastOptions, MessageType } from './toast.types'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private readonly message: MessageService) {}

  success(summary: string, options?: ToastOptions): void {
    this.message.add({
      ...options,
      severity: MessageType.SUCCESS,
      summary,
    })
  }

  warn(summary: string, options?: ToastOptions): void {
    this.message.add({
      ...options,
      severity: MessageType.WARN,
      summary,
    })
  }

  info(summary: string, options?: ToastOptions): void {
    this.message.add({
      ...options,
      severity: MessageType.INFO,
      summary,
    })
  }

  error(summary: string, options?: ToastOptions): void {
    this.message.add({
      ...options,
      severity: MessageType.ERROR,
      summary,
    })
  }

  custom(summary: string, options?: ToastOptions): void {
    this.message.add({
      ...options,
      severity: MessageType.CUSTOM,
      summary,
    })
  }
}
