import { Component } from '@angular/core'
import { PatientApiService } from '@services/patient/patient-api.service'
import { DynamicDialogRef } from 'primeng/dynamicdialog'

@Component({
  templateUrl: './server-unavailable-dialog.component.html',
  styleUrls: ['./server-unavailable-dialog.component.scss'],
})
export class ServerUnavailableDialogComponent {
  constructor(private readonly dialogRef: DynamicDialogRef, private readonly patientApi: PatientApiService) {}

  retry(): void {
    // TODO: replace with a request, which will check for a server availability
    // (no authorization, no response), after it will be added on the backend.
    this.patientApi.getProfile().subscribe(() => this.dialogRef.close())
  }
}
