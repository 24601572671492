<ng-container *ngIf="patients">
  <ng-container *ngIf="selectable; else nonSelectableTemplate">
    <app-selection-list
      [data]="patients"
      [itemTemplate]="selectablePatientTemplate"
      [selectedData]="[selectedPatient!]"
      [divided]="false"
      [unselectable]="false"
      selectionMode="border"
      [comparator]="comparator"
      (selectionChange)="onPatientSelectionChange($event)"
    >
    </app-selection-list>
  </ng-container>
</ng-container>

<ng-template #nonSelectableTemplate>
  <ng-container *ngFor="let patient of patients; let last = last">
    <ng-container
      [ngTemplateOutlet]="callbackOnClick || detailsOnClick ? patientClickableTemplate : patientNonClickableTemplate"
      [ngTemplateOutletContext]="{ $implicit: patient }"
    >
    </ng-container>

    <p-divider *ngIf="!last"></p-divider>
  </ng-container>
</ng-template>

<ng-template #selectablePatientTemplate let-patient let-clickable="clickable">
  <app-patient-row
    [patient]="patient"
    [clickable]="clickable"
    [anonymous]="anonymous"
    [itemEndTemplate]="itemEndTemplate"
    [showPersonalData]="showPersonalData"
  ></app-patient-row>
</ng-template>

<ng-template #patientClickableTemplate let-patient>
  <ng-container
    [ngTemplateOutlet]="callbackOnClick ? patientCallbackTemplate : patientHrefTemplate"
    [ngTemplateOutletContext]="{
      $implicit: patient,
      clickable: callbackOnClick || detailsOnClick
    }"
  >
  </ng-container>
</ng-template>

<ng-template #patientNonClickableTemplate let-patient>
  <app-patient-row
    [patient]="patient"
    [anonymous]="anonymous"
    [itemEndTemplate]="itemEndTemplate"
    [showPersonalData]="showPersonalData"
  ></app-patient-row>
</ng-template>

<ng-template #patientCallbackTemplate let-patient>
  <div class="flex-center-y">
    <input
      *ngIf="checkable"
      type="checkbox"
      class="p-checkbox mr-4 item-checkbox"
      [checked]="false"
      (change)="itemChecked(patient.id!)"
    />
    <div class="cursor-pointer w-100" (click)="onItemClicked(patient.id!)" pRipple>
      <app-patient-row
        [patient]="patient"
        [clickable]="true"
        [anonymous]="anonymous"
        [itemEndTemplate]="itemEndTemplate"
        [showPersonalData]="showPersonalData"
      ></app-patient-row>
    </div>
  </div>
</ng-template>

<ng-template #patientHrefTemplate let-patient>
  <a class="raw" [routerLink]="['/browse/patients', patient.id]" pRipple>
    <app-patient-row
      [patient]="patient"
      [clickable]="true"
      [anonymous]="anonymous"
      [itemEndTemplate]="itemEndTemplate"
      [showPersonalData]="showPersonalData"
    ></app-patient-row>
  </a>
</ng-template>
