import { Injectable, NgZone } from '@angular/core'
import { AbstractBroadcastService } from '@services/abstract-broadcast/abstract-broadcast.service'
import { BroadcastChannelService } from '@services/broadcast-channel/broadcast-channel.service'
import {
  BroadcastChannelMessage,
  BroadcastChannelMessageData,
  BroadcastChannelMessageType,
} from '@services/broadcast-channel/broadcast-channel.types'
import { SECONDS_IN_MINUTE } from '@services/user-idle/user-idle.helper'
import { UserIdleService } from '@services/user-idle/user-idle.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Injectable()
export class InactivityModalBroadcastService extends AbstractBroadcastService {
  readonly close$ = new Subject<boolean>()
  readonly tick$ = new Subject<number>()

  constructor(
    protected readonly broadcastChannel: BroadcastChannelService,
    protected readonly ngZone: NgZone,
    private readonly idle: UserIdleService,
  ) {
    super([BroadcastChannelMessageType.LOGOUT_DELAY_LEFT], broadcastChannel, ngZone)
  }

  get leader(): boolean {
    return this.broadcastChannel.leader
  }

  runDelayOrMessagesListener(): void {
    const delayLeftCallback = ({ data }: BroadcastChannelMessage) => this.handleTick(data)

    if (this.broadcastChannel.leader) {
      this.listenForDelay()
    } else {
      this.runListener(delayLeftCallback)
    }
  }

  private handleTick(data?: BroadcastChannelMessageData) {
    const secondsLeft = data?.secondsLeft

    if (!secondsLeft) {
      return
    }

    this.tick$.next(secondsLeft)
  }

  private listenForDelay(): void {
    this.idle.delay$.pipe(takeUntil(this.clear$)).subscribe(tick => {
      const secondsLeft = SECONDS_IN_MINUTE - tick

      if (this.broadcastChannel.leader) {
        const broadcastMessage = this.getDelayLeftMessage(secondsLeft)
        this.broadcastChannel.broadcastMessage(broadcastMessage)
      }

      if (tick === SECONDS_IN_MINUTE) {
        this.close$.next(true)

        return
      }

      this.tick$.next(secondsLeft)
    })
  }

  private getDelayLeftMessage(secondsLeft: number): BroadcastChannelMessage {
    const message: BroadcastChannelMessage = {
      type: BroadcastChannelMessageType.LOGOUT_DELAY_LEFT,
      data: {
        secondsLeft,
      },
    }

    return message
  }
}
