import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core'
import { GenericUser } from '@models/general.types'

@Component({
  selector: 'app-patient-list [patients]',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent {
  @Input()
  patients?: GenericUser[] | null
  @Input()
  callbackOnClick = false
  @Input()
  detailsOnClick = false
  @Input()
  anonymous = false
  @Input()
  nationality = false
  @Input()
  selectable = false
  @Input()
  showPersonalData = true
  @Input()
  itemEndTemplate?: TemplateRef<any>
  @Input()
  comparator?: (item: GenericUser) => boolean
  @Input()
  selectedPatient?: GenericUser
  @Input()
  checkable = false

  @Output()
  readonly itemClicked = new EventEmitter<number>()

  @Output()
  readonly patientSelected = new EventEmitter<GenericUser>()

  @Output()
  readonly patientChecked = new EventEmitter<number>()

  onItemClicked(patientId: number): void {
    if (!this.callbackOnClick) {
      return
    }

    this.itemClicked.emit(patientId)
  }

  onPatientSelectionChange([patient]: GenericUser[]): void {
    if (!this.selectable) {
      return
    }

    this.patientSelected.emit(patient)
  }

  itemChecked(patientId: number): void {
    if (!this.checkable) {
      return
    }

    this.patientChecked.next(patientId)
  }
}
