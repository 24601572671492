import { NgZone } from '@angular/core'
import { defer, Observable, OperatorFunction } from 'rxjs'
import { tap } from 'rxjs/operators'

export function tapOnce<T>(callback: (value: T) => void): OperatorFunction<T, T> {
  return source =>
    defer(() => {
      let first = true

      return source.pipe(
        tap(payload => {
          if (first) {
            callback(payload)
          }

          first = false
        }),
      )
    })
}

export function runInZone<T>(ngZone: NgZone): OperatorFunction<T, T> {
  return source => {
    return new Observable(observer => {
      const onNext = (value: T) => ngZone.run(() => observer.next(value))
      const onError = (e: any) => ngZone.run(() => observer.error(e))
      const onComplete = () => ngZone.run(() => observer.complete())
      return source.subscribe(onNext, onError, onComplete)
    })
  }
}
