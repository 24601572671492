import { Pipe, PipeTransform } from '@angular/core'
import { replaceAtIndex } from '@helpers/strings.helper'
import { GenericUser } from '@models/general.types'

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform({ gender }: GenericUser): string {
    if (!gender) {
      return ''
    }

    return replaceAtIndex(gender.toLowerCase(), 0, gender.charAt(0))
  }
}
