import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { BroadcastChannelService } from '@services/broadcast-channel/broadcast-channel.service'
import { EnvironmentService } from '@services/environment/environment.service'
import { PrimeNGConfig } from 'primeng/api'
import { AppUpdateBroadcastService } from './app-update-broadcast.service'
import { AppPwaService } from './app-pwa.service'
import { TranslateService } from '@ngx-translate/core'
import 'default-passive-events'
import { TOAST_BREAKPOINTS } from '@helpers/breakpoints.helper'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [AppPwaService, AppUpdateBroadcastService],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly toastBreakpoints = TOAST_BREAKPOINTS

  constructor(
    protected readonly environment: EnvironmentService,
    private readonly title: Title,
    private readonly broadcastChannel: BroadcastChannelService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly appUpdateBroadcast: AppUpdateBroadcastService,
    private readonly appPwa: AppPwaService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    const updateCallback = () => this.appPwa.activateUpdate()

    this.setDefaultPageTitle()
    this.setPrimeNgRipple()
    this.setPrimeNgTranslations()

    this.broadcastChannel.open()
    this.appUpdateBroadcast.runListener(updateCallback)
    this.appPwa.listenForInstallationEvent()
    this.appPwa.listenForUpdateEvent()
  }

  ngOnDestroy(): void {
    this.appUpdateBroadcast.clearListener()
    this.broadcastChannel.close()
  }

  private setDefaultPageTitle(): void {
    const { appName } = this.environment

    this.title.setTitle(appName)
  }

  private setPrimeNgRipple(): void {
    this.primengConfig.ripple = true
  }

  private setPrimeNgTranslations(): void {
    this.translate.get('primeng').subscribe(translations => this.primengConfig.setTranslation(translations))
  }
}
