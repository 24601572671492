import { Pipe, PipeTransform } from '@angular/core'
import * as _ from 'lodash'

@Pipe({
  name: 'selectionListChecked',
})
export class SelectionListCheckedPipe<T> implements PipeTransform {
  transform(conditionItem?: T | Array<T>, item?: T, comparator?: (item: T) => boolean): boolean {
    if (!item) {
      return false
    }
    if (Array.isArray(conditionItem)) {
      const foundItem = conditionItem!.find(it => _.isEqualWith(item, it, comparator))
      return !!foundItem
    }
    return _.isEqual(item, conditionItem)
  }
}
