import { MenuItem } from 'primeng/api'

export const MENU_ITEMS: MenuItem[] = [
  // {
  //   label: 'menu.home',
  //   routerLink: '/home',
  //   icon: 'assets/icons/home.svg',
  // },
  {
    label: 'menu.appointments',
    routerLink: '/appointments',
    icon: 'assets/icons/appointments.svg',
  },
  {
    label: 'menu.mdcare',
    routerLink: '/account',
    icon: 'assets/icons/Icon.svg',
  },
  {
    label: 'menu.account',
    routerLink: '/menu',
    items: [
      {
        label: 'menu.profile',
        routerLink: '/profile',
      },
      {
        label: 'menu.healthprofile',
        routerLink: '/helthprofile',
      },
      {
        label: 'menu.billings',
        routerLink: '/billings',
      },
      {
        label: 'menu.card',
        routerLink: '/card',
      },
    ],
    icon: 'assets/icons/pvw24J01.svg',
  },
  {
    label: 'menu.inbox',
    routerLink: '/inbox',
    icon: 'assets/icons/hfDHLA01.svg',
  },
]
