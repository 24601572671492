import { Component, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Breakpoint } from '@models/breakpoints.types'
import { BreakpointsService } from '@services/breakpoints/breakpoints.service'

type BackButtonIcon = 'back-arrow' | 'close'

@Component({
  selector: 'app-page-header [mobileTitle] [desktopTitle]',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  readonly currentBreakpoint$ = this.breakpoints.currentBreakpoint$
  readonly Breakpoint = Breakpoint

  @Input()
  mobileTitle!: string
  @Input()
  desktopTitle!: string
  @Input()
  backButton = false
  @Input()
  backButtonIcon: BackButtonIcon = 'back-arrow'

  constructor(
    private readonly breakpoints: BreakpointsService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  goBack(): void {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute })
  }
}
