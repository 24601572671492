import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class HttpActiveCallsService {
  unsubscribe$ = new Subject<void>()

  onUnsubscribe(): void {
    this.unsubscribe$.next()
  }
}
