import { Component, Input } from '@angular/core'
import { InputControlAbstractComponent } from '@components/input-control/input-control.abstract'

@Component({
  selector: 'app-textarea-control',
  templateUrl: './textarea-control.component.html',
})
export class TextareaControlComponent extends InputControlAbstractComponent {
  @Input()
  maxLength = 1000

  @Input()
  rows = 2
}
