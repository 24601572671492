<div class="p-dialog-content">
  <i class="pi pi-exclamation-triangle"></i>

  {{ 'general.inactivityDescription' | translate: { seconds: secondsLeft } }}
</div>

<div class="p-dialog-footer">
  <p-button styleClass="p-button-outlined" [label]="'general.stay' | translate" (onClick)="dismiss()"> </p-button>

  <p-button [label]="'general.logout' | translate" (onClick)="close(false)"> </p-button>
</div>
