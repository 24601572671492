import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ApiValidationErrorMsgDto } from '@models/api-errors.types'
import { ErrorMessages } from '@components/form-field/inline-errors/inline-errors.types'

@Component({
  selector: 'app-form-field',
  template: `
    <div [class.field-error]="isError">
      <ng-content></ng-content>

      <app-inline-errors
        *ngIf="form"
        [form]="form"
        [fieldName]="field"
        [errorMessages]="inlineMessages"
        (errors)="onErrors($event)"
      ></app-inline-errors>

      <app-server-errors
        *ngIf="serverMessages"
        [errorMessages]="serverMessages"
        [fieldName]="field"
      ></app-server-errors>
    </div>
  `,
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  isError = false

  @Input()
  form!: FormGroup
  @Input()
  field!: string
  @Input()
  inlineMessages?: ErrorMessages
  @Input()
  serverMessages?: ApiValidationErrorMsgDto[]

  onErrors($event: boolean): void {
    this.isError = $event
  }
}
