import { Component, Input } from '@angular/core'
import { EMPTY_VALUE_PLACEHOLDER } from '@helpers/general.helper'
import { ReadonlyInputValue } from '@models/general.types'

@Component({
  selector: 'app-readonly-textarea [value]',
  templateUrl: './readonly-textarea.component.html',
  styleUrls: ['./readonly-textarea.component.scss'],
})
export class ReadonlyTextareaComponent {
  readonly emptyValuePlaceholder = EMPTY_VALUE_PLACEHOLDER

  @Input()
  labelKey?: string
  @Input()
  value!: ReadonlyInputValue
}
