import { Injectable } from '@angular/core'
import { ConfirmDialogService } from '@components/confirm-dialog/confirm-dialog.service'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthLogoutService {
  listener$ = new Subject<void>()

  constructor(private readonly confirmDialog: ConfirmDialogService) {}

  submit(): void {
    this.listener$.next()
  }

  openLogoutConfirmDialog(): void {
    this.confirmDialog.open({
      accept: () => this.submit(),
    })
  }
}
