<div class="image-cropper">
  <image-cropper
    class="mb-4"
    [imageChangedEvent]="fileEvent"
    (imageCropped)="onImageCropped($event)"
    [roundCropper]="config.roundCropper"
    [cropperMinHeight]="config.cropperMinHeight"
    [cropperMinWidth]="config.cropperMinWidth"
    [maintainAspectRatio]="config.maintainAspectRatio"
    [resizeToHeight]="config.resizeToHeight"
    [resizeToWidth]="config.resizeToWidth"
    [transform]="cropperTransform"
    (loadImageFailed)="onImageLoadFailed()"
    (imageLoaded)="onImageLoaded()"
  ></image-cropper>
  <div class="spinner" *ngIf="loading; else actions">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>

<ng-template #actions>
  <div class="actions">
    <div class="zoom-slider mb-4">
      <i class="pi pi-search-minus mr-4"></i>
      <div class="flex-grow-1 flex-column-justify-center">
        <p-slider
          [(ngModel)]="zoom"
          [min]="zoomConfig.minZoom"
          [max]="zoomConfig.maxZoom"
          [step]="zoomConfig.zoomStep"
          (ngModelChange)="onZoomChange()"
        ></p-slider>
      </div>
      <i class="pi pi-search-plus ml-4"></i>
    </div>
    <div class="flex-center-x">
      <p-button (onClick)="saveImage()" [label]="'general.save' | translate"></p-button>
    </div>
  </div>
</ng-template>
