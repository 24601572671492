import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { ActivationEnd, Router } from '@angular/router'
import { EnvironmentService } from '@services/environment/environment.service'
import { NgProgress } from 'ngx-progressbar'
import { Observable, Subject } from 'rxjs'
import { filter, finalize, takeUntil } from 'rxjs/operators'
import { HttpActiveCallsService } from '../services/http/http-subscriptions.service'

@Injectable({
  providedIn: 'root',
})
export class HttpClientInterceptor implements HttpInterceptor, OnDestroy {
  destroy$ = new Subject<void>()

  constructor(
    protected readonly router: Router,
    private readonly activeCalls: HttpActiveCallsService,
    private readonly environment: EnvironmentService,
    private readonly ngProgress: NgProgress,
  ) {
    router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.activeCalls.onUnsubscribe())
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const request = next.handle(req).pipe(finalize(() => this.ngProgress.ref().complete()))

    this.ngProgress.ref().start()

    if (req.url.startsWith(this.environment.apiUrl)) {
      return request.pipe(takeUntil(this.activeCalls.unsubscribe$))
    }

    return request
  }
}
