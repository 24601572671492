import { Injectable, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { BroadcastChannelService } from '@services/broadcast-channel/broadcast-channel.service'
import {
  BroadcastChannelMessage,
  BroadcastChannelMessageType,
} from '@services/broadcast-channel/broadcast-channel.types'
import { StorageService } from '@services/storage/storage.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { finalize, takeUntil } from 'rxjs/operators'
import { AuthApiService } from './auth-api.service'
import { AuthLogoutService } from './auth-logout.service'
import { UserInfoDto } from '@models/api/userInfoDto'
import { EnvironmentService } from '@services/environment/environment.service'

const LOGGED_USER_KEY = 'loggedUser'

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly destroy$ = new Subject<void>()
  userChanges$ = new BehaviorSubject<UserInfoDto | undefined>(this.loggedUser)
  idleError = false
  prefix: string

  constructor(
    private readonly storage: StorageService,
    private readonly authApi: AuthApiService,
    private readonly router: Router,
    private readonly authLogout: AuthLogoutService,
    private readonly broadcastChannel: BroadcastChannelService,
    private readonly environment: EnvironmentService,
  ) {
    this.listenForLogout()
    this.prefix = this.environment.uniqueIdentifier
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  get loggedUser(): UserInfoDto | undefined {
    var userData: any = window.localStorage.getItem(`${this.prefix}-LOGGED_USER_KEY`)
    return userData
  }

  set loggedUser(user: UserInfoDto | undefined) {
    if (!user) {
      // this.storage.delete(LOGGED_USER_KEY)
      window.localStorage.removeItem(`${this.prefix}-LOGGED_USER_KEY`)
    } else {
      window.localStorage.setItem(`${this.prefix}-LOGGED_USER_KEY`, JSON.stringify(user))
    }
    this.emitUserChanges(this.loggedUser)
  }

  get isLogged(): boolean {
    return this.loggedUser !== null && this.loggedUser !== undefined
  }

  get shouldRegister(): boolean {
    return !!this.loggedUser?.registration
  }

  private listenForLogout(): void {
    this.authLogout.listener$.pipe(takeUntil(this.destroy$)).subscribe(() => this.handleLogout())
  }

  private handleLogout(): void {
    this.authApi
      .logout()
      .pipe(finalize(() => this.finalizeLogout()))
      .subscribe(
        () => {},
        error => {
          console.error('Logout failed:', error)
          // Handle error here, such as displaying an error message to the user
        },
      )
  }
  private finalizeLogout(): void {
    this.loggedUser = undefined

    const logoutMessage = this.getLogoutMessage()

    this.broadcastChannel.broadcastMessage(logoutMessage)
    this.router.navigateByUrl('/login', { state: { idleError: this.idleError } })
  }

  private getLogoutMessage(): BroadcastChannelMessage {
    const message: BroadcastChannelMessage = {
      type: BroadcastChannelMessageType.CLEAR_AUTH_SESSION,
      data: {
        idleError: this.idleError,
      },
    }

    return message
  }

  emitUserChanges(user?: UserInfoDto) {
    this.userChanges$.next(user)
  }
}
