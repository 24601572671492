import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { MenuItem } from 'primeng/api'
import { MENU_ITEMS } from '../menu.helper'
import { AuthLogoutService } from '@services/auth/auth-logout.service'
import { Router } from '@angular/router'
import { TopBarApiService } from './top-bar-api.service'
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  providers: [TopBarApiService],
})
export class TopBarComponent implements OnInit {
  menuItems: MenuItem[] = []
  engIcon: string = 'assets/images/britain.png'
  swedIcon: string = 'assets/images/sweden.png'
  profileIcon: string = 'assets/images/profile.png'
  logoutIcon: string = 'assets/icons/logout.svg'
  langModel: boolean = false
  profileModel: boolean = false
  firstName: any = ''
  lang: {
    icon: string
    code: string
  } = {
    icon: this.engIcon,
    code: 'ENG',
  }
  langCode?: any
  constructor(
    private readonly translate: TranslateService,
    private readonly authLogout: AuthLogoutService,
    private router: Router,
    private readonly TopBarApiService: TopBarApiService,
  ) {
    this.translateMenuItems()
  }
  ngOnInit(): void {
    this.langCode = window.localStorage.getItem('lang')
    console.log(this.langCode)
    if (this.langCode) {
      this.changeLang(this.langCode)
      this.langModel = false
    } else {
      if (this.translate.currentLang === 'en') {
        this.lang = {
          icon: this.engIcon,
          code: 'ENG',
        }
      } else {
        this.lang = {
          icon: this.swedIcon,
          code: 'SWE',
        }
      }
    }
    this.TopBarApiService.getUserProfile().subscribe(res => {
      this.firstName = res.firstName
    })
  }

  private translateMenuItems() {
    this.menuItems = MENU_ITEMS.map(item => {
      const translatedItem: MenuItem = {
        ...item,
        routerLink: item.items ? null : item.routerLink,
        label: this.translate.instant(item.label ?? ''),
      }

      if (item.items) {
        translatedItem.items = item.items.map(subItem => ({
          ...subItem,
          label: this.translate.instant(subItem.label ?? ''),
        }))
      }

      return translatedItem
    })
  }
  openLangModel() {
    this.langModel = !this.langModel
    this.profileModel = false
  }
  openProfileMOdel() {
    this.profileModel = !this.profileModel
    this.langModel = false
  }

  changeLang(code: string) {
    if (code === 'en') {
      this.lang = {
        icon: this.engIcon,
        code: 'ENG',
      }
    } else {
      this.lang = {
        icon: this.swedIcon,
        code: 'SWE',
      }
    }
    this.translate.setDefaultLang(code)
    this.translate.use(code).subscribe(res => {
      this.translateMenuItems()
      window.localStorage.setItem('lang', code)
    })
    this.openLangModel()
  }

  changeRoute() {
    this.router.navigate(['/profile'])
    this.profileModel = false
    this.langModel = false
  }
  logout(): void {
    this.authLogout.openLogoutConfirmDialog()
    this.profileModel = false
    this.langModel = false
  }
}
