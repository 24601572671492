import { Injectable } from '@angular/core'
import { EnvironmentService } from '@services/environment/environment.service'
import { EMPTY, interval, Subject, timer } from 'rxjs'
import { startWith, switchMap } from 'rxjs/operators'
import { MILLIS_IN_MINUTE, MILLIS_IN_SECOND } from './user-idle.helper'

@Injectable({
  providedIn: 'root',
})
export class UserIdleService {
  private readonly reset$ = new Subject<void>()
  private readonly timeoutValue = this.environment.userIdleTimeout * MILLIS_IN_MINUTE
  readonly timer$ = this.reset$.pipe(
    startWith(EMPTY),
    switchMap(() => timer(this.timeoutValue)),
  )
  readonly delay$ = interval(MILLIS_IN_SECOND)

  constructor(private readonly environment: EnvironmentService) {}

  resetTimer(): void {
    this.reset$.next()
  }
}
