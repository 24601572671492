<label>
  <span *ngIf="labelKey">{{ labelKey | translate }}</span>

  <textarea
    class="w-100"
    [value]="value || emptyValuePlaceholder"
    [autoResize]="true"
    pInputTextarea
    disabled
  ></textarea>
</label>
