<div *ngIf="backButton">
  <a *appBreakpoint="Breakpoint.XS" class="back-icon" (click)="goBack()">
    <svg-icon [src]="backButtonIcon | iconSource"></svg-icon>
  </a>
</div>

<div class="page-header">
  <div *ngIf="backButton" class="back-icon">
    <p-button
      *appBreakpoint="Breakpoint.XS; mode: 'exclude'"
      styleClass="p-button-text p-button-rounded p-2"
      (onClick)="goBack()"
    >
      <ng-template pTemplate="content">
        <svg-icon [src]="'back-arrow' | iconSource"></svg-icon>
      </ng-template>
    </p-button>
  </div>

  <h1 class="page-header-title">
    {{ (currentBreakpoint$ | async) === Breakpoint.XS ? mobileTitle : desktopTitle }}
  </h1>
</div>
